import "./assets/css/style.default.css";
import "./assets/css/custom.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./router/Layout";
import Home from "./pages/home/Home";
import Tienda from "./pages/tienda/Tienda";
import Detalles from "./pages/detalles/Detalles";
import { ArticuloProvider } from "./context/ArticuloContext/ArticuloContext";
import Carrito from "./pages/carrito/Carrito";
import { CarritoProvider } from "./context/CarritoContext/CarritoContext";
import { MaintenanceProvider } from "./context/MaintenanceContect/MaintenanceContect";
import Pago from "./pages/pago/Pago";
import "@splidejs/react-splide/css";
import Footer from "./components/Footer";
import Contacto from "./pages/contacto/Contacto";
import Acerca from "./pages/Acerca/Acerca";
import Portafolio from "./pages/Portafolio/Portafolio";
import NoPage from "./pages/NoPage/NoPage";
import InicionSecion from "./pages/inicionSecion/InicioSesion";
import Mantenimiento from "./pages/Mantenimiento/Mantenimiento";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ArticuloProvider>
          <CarritoProvider>
            <MaintenanceProvider>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="/tienda">
                    <Route index element={<Tienda />} />
                    <Route path=":id" element={<Detalles />} />
                  </Route>
                  <Route path="/carrito">
                    <Route index element={<Carrito />} />
                    <Route path="pagos" element={<Pago />} />
                  </Route>
                  <Route path="/informacion" element={<Acerca />} />
                  <Route path="/contacto" element={<Contacto />} />
                  <Route path="/portafolio" element={<Portafolio />} />
                  <Route path="/signIn" element={<InicionSecion />} />
                  <Route path="/mantenimiento" element={<Mantenimiento />} />
                  <Route path="*" exact={true} element={<NoPage />} />
                </Route>
              </Routes>
            </MaintenanceProvider>
          </CarritoProvider>
        </ArticuloProvider>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
