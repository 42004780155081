import React from "react";
import { Link } from "react-router-dom";
import CarritoItems from "../../components/CarritoItems";
import Hero from "../../components/Hero";
import Total from "../../components/Total";
import { useCarrito } from "../../hooks/useCarrito";

function Carrito() {
  const { state } = useCarrito();
  return (
    <>
      <Hero base={"Tienda"} go={"Carrito"} />
      <div className="container">
        <section className="py-5 custom-height">
          <h2 className="h5 text-uppercase mb-4">Mi Carrito</h2>
          <div className="row">
            <div className="col-lg-8 mb-4 mb-lg-0">
              <div className="table-responsive mb-4">
                <CarritoItems />
              </div>
              <div className="bg-light px-4 py-3">
                <div className="row align-items-center text-center">
                  <div className="col-md-6 mb-3 mb-md-0 text-md-start">
                    <Link
                      to="/tienda"
                      className="btn btn-link p-0 text-dark btn-sm"
                    >
                      <i className="fas fa-long-arrow-alt-left me-2"></i>
                      Continuar comprando
                    </Link>
                  </div>
                  <div className="col-md-6 text-md-end">
                    {state.carrito.length === 0 ? (
                      <></>
                    ) : (
                      <Link to="pagos" className="btn btn-outline-dark btn-sm">
                        Cotizar Whatsapp
                        <i className="fas fa-long-arrow-alt-right ms-2"></i>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <Total />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Carrito;
