import React from "react";
import Mailto from "react-mailto-link";

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-sm-4 col-md-4 col-lg-4">
            <ul className="list-group list-group-flush">
              <li className="titulo">
                <i className="fa fa-phone"></i> Numeros
              </li>
              <li className="">+506 8729 4117</li>
              <li className="">+506 6068 4834</li>
            </ul>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4">
            <ul className="list-group list-group-flush">
              <li className="titulo">Acerca de la tienda</li>
              <li className="">
                Servicio al cliente 24/7 por Whatsapp o correo{" "}
              </li>
              <li className="">Para solicitudes de cotización en línea 24/7</li>
            </ul>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4">
            <ul className="list-group list-group-flush">
              <li className="titulo">
                <i className="fa fa-envelope"></i> Consultas
              </li>
              <li className="email__footer">
                <Mailto email="Ventas07@fmhidalgo.com">
                  Ventas07@fmhidalgo.com
                </Mailto>
              </li>
              <li className="email__footer">
                <Mailto email="mayronhidalgo95@gmail.com">
                  mayronhidalgo95@gmail.com
                </Mailto>
              </li>
              <li className="email__footer">
                <Mailto email="grosales@fmhidalgo.com">
                  grosales@fmhidalgo.com
                </Mailto>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
