import React, { useState } from "react";
import Select from "react-select";
import Hero from "../../components/Hero";
import Total from "../../components/Total";
import { Inputs } from "../../utils/formSource";

function Pago() {
  const [data, setData] = useState({});

  const provincias = [
    { label: "San Jose", value: 1 },
    { label: "Alajuela", value: 2 },
    { label: "Cartago", value: 3 },
    { label: "Heredia", value: 4 },
    { label: "Guanacaste", value: 5 },
    { label: "Puntarenas", value: 6 },
    { label: "Limon", value: 7 },
  ];

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({
      ...data,
      [id]: value,
      ["carrito"]: JSON.parse(localStorage.getItem("carrito")),
    });
  };

  const handleChange = (e) => {
    const label = e.label;

    setData({
      ...data,
      ["provincia"]: label,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let stringCarrito = '';
    for (let index = 0; index < data.carrito.length; index++) {
      stringCarrito += `%0aArticulo:${data.carrito[index].nomArticulo}%0aCantidad:${data.carrito[index].cantidad}%0aColor:${data.carrito[index].nomColor}%0a`;
    }
    
    window.location.replace(
      `https://wa.me/50687294117?text=Nombre:%20${data.primerNombre}%0aApellido:${data.primerApellido}
       %0aCorreo:${data.correo}%0aTelefono:${data.telefono}%0aDireccion:${data.direccion2}%0aProductos:%0a${stringCarrito}`,
      "_blank"
    )
    .focus();
  };

  return (
    <div className="container">
      {/* <!-- HERO SECTION--> */}
      <Hero base={"Carrito"} go={"Solicitud"} />
      <section className="py-5">
        {/* <!-- BILLING ADDRESS--> */}
        <h2 className="h5 text-uppercase mb-4">Billing details</h2>
        <div className="row">
          <div className="col-lg-8">
            <form onSubmit={handleSubmit}>
              <div className="row gy-3">
                {Inputs.map((input) => (
                  <div className={input.class} key={input.id}>
                    <label className="form-label text-sm text-uppercase">
                      {input.label}
                    </label>
                    <input
                      className="form-control form-control-lg"
                      type={input.type}
                      id={input.id}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      required
                    />
                  </div>
                ))}
                {/* <div className="col-lg-6 form-group">
                  <label className="form-label text-sm text-uppercase">
                    Provincias
                  </label>
                  <Select options={provincias} onChange={handleChange} />
                </div> */}
                <div className="col-lg-12 form-group">
                  <button className="btn btn-light w-100 send-info" type="submit">
                    Enviar
                    <span className="whatsapp-icon"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* <!-- ORDER SUMMARY--> */}
          <div className="col-lg-4">
            <Total />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pago;
