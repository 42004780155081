import { Splide, SplideSlide } from "@splidejs/react-splide";
import { CLASS_TOGGLE, Options } from "@splidejs/splide";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import DetallesArticulo from "../../components/DetallesArticulo";
import DetallesArticuloDescp from "../../components/DetallesArticuloDescp";
import { useArticulos } from "../../hooks/useArticulos";
import {
  BASE_URL,
  fetchArticulosById,
  fetchColoresById,
  fetchImgByIdArticulos,
} from "../../services/services";
import { ACTION_TYPES_ARTICULO } from "../../utils/articulosReducer/initialStateArticulos";
import ModalSwiper from "../../components/ModalSwiper";
import QuickArticules from "../../components/quickArticules/QuickArticules";
import Articulos from "../../components/Articulos";

const Detalles = () => {
  const { dispatch, articulo, isLoadingDetalles, noExists } = useArticulos();
  const [images, setImages] = useState();
  const [copyArr, setCopyArr] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const { id } = useParams();

  const handleFetchArticulosById = async () => {

    dispatch({
      type: ACTION_TYPES_ARTICULO.NO_EXISTS,
      payload: false,
    });
    
    try {
      const response = fetchArticulosById(id);
      const data = await response;

      if (data?.error)
        return dispatch({
          type: ACTION_TYPES_ARTICULO.NO_EXISTS,
          payload: true,
        });

      const responseColor = fetchColoresById(id);
      const dataColor = await responseColor;

      const responseImage = fetchImgByIdArticulos(id);
      const dataImage = await responseImage;

      dispatch({
        type: ACTION_TYPES_ARTICULO.FETCH_ARTICULO_BY_ID,
        payload: data,
      });

      dispatch({
        type: ACTION_TYPES_ARTICULO.FETCH_COLORS_BY_ID,
        payload: dataColor,
      });
      setImages(dataImage);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleFetchArticulosById();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [id]);

  const renderArticles = () => {
    return articulo?.map((item, index) => (
      <div key={index} className="col-sm-10 order-1 order-sm-2">
        <div className="swiper product-slider">
          <div className="swiper-wrapper">
            <div className="swiper-slide h-auto">
              <a
                className="glightbox product-view"
                data-gallery="gallery2"
                data-glightbox="Product item 4"
              >
                <img
                  className="img-fluid"
                  src={BASE_URL + "resources/" + item.imgPreviewArticulos.img}
                  alt="..."
                  onClick={() => orderImage(item.imgPreviewArticulos, true)}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const orderImage = (imgArticulo, active) => {
    while (copyArr.length > 0) {
      copyArr.pop();
    }

    images.map((img) => copyArr.push(img));
    if (!active) {
      const index = copyArr.map((x) => x.id).indexOf(imgArticulo.id);
      copyArr.splice(index, 1);
      copyArr.push(articulo[0]?.imgPreviewArticulos);
    }

    copyArr.unshift(imgArticulo);
    setModalShow(true);
  };

  return (
    <>
      <ModalSwiper
        show={modalShow}
        onHide={() => setModalShow(false)}
        copyarr={copyArr}
      />
      <section className="py-5">
        <div className="container">
          {noExists == true ? (
            <div className="loader-custom no-exists">
              <div></div>
              <label>No existe este producto</label>
            </div>
          ) : (
            <>
              {isLoadingDetalles == true ? (
                <div className="loader-custom">
                  <div></div>
                  <label>Cargando</label>
                  <span className="loader-xd"></span>
                </div>
              ) : (
                <>
                  <div className="row mb-5">
                    <div className="col-lg-6">
                      <div className="row m-sm-0">
                        <div className="col-sm-2 p-sm-0 order-2 order-sm-1 mt-2 mt-sm-0 px-xl-2">
                          <div className="swiper product-slider-thumbs">
                            <div className="d-flex flex-column">
                              {images?.map((imagesArticuloById) => (
                                <div
                                  className="swiper-slide h-auto swiper-thumb-item mb-3"
                                  key={imagesArticuloById.id}
                                >
                                  <img
                                    className="w-100"
                                    src={
                                      BASE_URL +
                                      "resources/" +
                                      imagesArticuloById.img
                                    }
                                    alt="..."
                                    onClick={() => {
                                      orderImage(imagesArticuloById);
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {renderArticles()}
                      </div>
                    </div>

                    <DetallesArticulo />
                  </div>
                  <ul
                    className="nav nav-tabs border-0"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link text-uppercase active"
                        id="description-tab"
                        data-bs-toggle="tab"
                        href="#description"
                        role="tab"
                        aria-controls="description"
                        aria-selected="true"
                      >
                        Detalles
                      </a>
                    </li>
                  </ul>
                  {/* <div className="tab-content mb-5" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="description"
              role="tabpanel"
              aria-labelledby="description-tab"
            >
              <div className="p-4 p-lg-5 bg-white">
                <h6 className="text-uppercase">Descripcion del Producto</h6>
                <p className="text-muted text-sm mb-0"></p>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="reviews"
              role="tabpanel"
              aria-labelledby="reviews-tab"
            ></div>
          </div> */}
                  <DetallesArticuloDescp />
                </>
              )}
            </>
          )}

          {/* <!-- RELATED PRODUCTS--> */}
          <h2 className="h5 text-uppercase mb-4">Muebles populares</h2>
          {/* <QuickArticules /> */}
          <Articulos popularProduct={true} />
        </div>
      </section>
    </>
  );
};

export default Detalles;
