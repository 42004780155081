import React from 'react';
import error404 from '../../assets/img/404.png';
import Hero from '../../components/Hero';

const NoPage = () => {
    return (
        <>
            <Hero base={"Inicio"} go={"No existe la pagina"} />
            <div className='NotFound'>
                <img src={error404}></img>
            </div>
        </>
    );
};

export default NoPage;