import { useContext } from "react";
import { ArticuloContext } from "../context/ArticuloContext/ArticuloContext";
import paginator from "../utils/paginator";

export const useArticulos = () => {
  const {
    state,
    dispatch,
    handleFilterByCategoria,
    handleFilterBySubCategoria,
    handleFetchArticulosColores,
    handleFetchArticulosSubCategorias,
    handleReset,
  } = useContext(ArticuloContext);
  
  // todo lo que se agrego aqui hasta el siguente comenterio es para hacer funcionar la funcion de paginas
  const {
    isLoading,
    isError,
    articulo,
    initialArticulo,
    colores,
    page,
    articuloByFilters,
    filterState,
    inputSearch,
    isLoadingDetalles,
    noExists,
    articuloNombre,
    articuloCodigo,
    articuloPrecio,
    articuloDescripcionCorta,
    articuloDescripcionLarga,
  } = state;

  const articulosPage = paginator(
    articuloByFilters.length > 0 && filterState
      ? articuloByFilters
      : articuloByFilters.length === 0 && filterState
      ? []
      : initialArticulo
  );

  // aqui finaliza nota (no se debio llamar a datos que no se usan)
  return {
    isLoading,
    isError,
    articulo,
    initialArticulo,
    dispatch,
    colores,
    page,
    filterState,
    articuloByFilters,
    articulosPage,
    handleFilterByCategoria,
    handleFilterBySubCategoria,
    handleFetchArticulosColores,
    handleFetchArticulosSubCategorias,
    handleReset,
    inputSearch,
    isLoadingDetalles,
    noExists,
    articuloNombre,
    articuloCodigo,
    articuloPrecio,
    articuloDescripcionCorta,
    articuloDescripcionLarga,
    state,
  };
};
