export const INITIAL_STATE_ARTICULO = {
  isLoading: true,
  isError: false,
  initialArticulo: [],
  articuloByFilters: [],
  articulo: [],
  colores: [],
  page: 0,
  filterState: false,
  inputSearch: "",
  isLoadingDetalles:true,
  noExists:false,
  articuloNombre:"",
  articuloCodigo:"",
  articuloPrecio:0,
  articuloDescripcionCorta:"",
  articuloDescripcionLarga:"",
  articuloColores:[],
  articulosSuCategoria:[],
  articulosSuCategoriaSelecionado:[],
  articuloColoresSeleccionados:[],
  articuloPrevImage:null,
  articuloGeneralImages:null,
  articuloGuardado:false,
};

export const ACTION_TYPES_ARTICULO = {
  SETARTICULOGUARDADO: "SETARTICULOGUARDADO",
  SET_ARTICULO_GENERAL_IMAGE: "SET_ARTICULO_GENERAL_IMAGE",
  SET_ARTICULO_PREV_IMAGE: "SET_ARTICULO_PREV_IMAGE",
  SET_ARTICULO_SUBCATEGORIAS: "SET_ARTICULO_SUBCATEGORIAS",
  SET_ARTICULO_DESC_CORTA: "SET_ARTICULO_DESC_CORTA",
  SET_ARTICULO_DESC_LARGA: "SET_ARTICULO_DESC_LARGA",
  SET_ARTICULO_PRECIO: "SET_ARTICULO_PRECIO",
  SET_ARTICULO_NOMBRE: "SET_ARTICULO_NOMBRE",
  SET_ARTICULO_CODIGO: "SET_ARTICULO_CODIGO",
  SET_ARTICULO_COLORES: "SET_ARTICULO_COLORES",
  SET_ARTICULO_COLORES_SELECCIONADOS: "SET_ARTICULO_COLORES_SELECCIONADOS",
  SET_ARTICULO_SUBCATEGORIA_SELECCIONADO: "SET_ARTICULO_SUBCATEGORIA_SELECCIONADO",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_ERROR: "FETCH_ERROR",
  FETCH_ARTICULO_BY_ID: "FETCH_ARTICULO_BY_ID",
  FETCH_COLORS_BY_ID: "FETCH_COLORS_BY_ID",
  SET_PAGE: "SET_PAGE",
  FILTER_CAT_BY_CATEGORIA: "FILTER_CAT_BY_CATEGORIA",
  FILTER_CAT_BY_SUBCATEGORIA: "FILTER_CAT_BY_SUBCATEGORIA",
  SEARCH_ARTICULO: "SEARCH_ARTICULO",
  SET_INITIAL_DATA: "SET_INITIAL_DATA",
  RESET_LOARDER_ARTICULO:"RESET_LOARDER_ARTICULO",
  NO_EXISTS:"NO_EXISTS",
  RESET: "RESET",
  RESETADD: "RESETADD",
};
