export const subTotal = (carrito) => {
  return carrito.reduce(
    (acc, item) => (acc += (item.precioColor + item.costo) * item.cantidad),
    0
  );
};

export const iva = (carrito) => {
  return subTotal(carrito) * 0.13;
};
