import React from "react";
import { useEffect } from "react";
import Articulos from "../../components/Articulos";
import Categorias from "../../components/Categorias";
import Hero from "../../components/Hero";
import { useArticulos } from "../../hooks/useArticulos";
import { ACTION_TYPES_ARTICULO } from "../../utils/articulosReducer/initialStateArticulos";

function Tienda() {
  const {
    page,
    dispatch,
    articulosPage,
    initialArticulo,
    filterState,
    articuloByFilters,
    inputSearch,
  } = useArticulos();

  useEffect(() => {
    dispatch({
      type: ACTION_TYPES_ARTICULO.RESET,
    });
  }, []);

  const showFilterState = () => {
    return filterState ? (
      articuloByFilters?.length === 0 ? (
        <div>No hay elementos</div>
      ) : (
        "Mostrando " +
        (articulosPage[page]?.length ? articulosPage[page]?.length : 0) +
        " de " +
        articuloByFilters?.length +
        " resultados"
      )
    ) : (
      "Mostrando " +
        (articulosPage[page]?.length ? articulosPage[page]?.length : 0) +
        " de " +
        initialArticulo?.length +
        " resultados"
    );
  };

  return (
    <div>
      <Hero base={"Inicio"} go={"Tienda"} />
      <section className="py-5">
        <div className="container p-0">
          <div className="row m-0 TEST">
            <Categorias />
            <div className="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">
              <div className="row mb-3 align-items-center">
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <span className="text-sm text-muted mb-0">
                    {showFilterState()}
                  </span>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex align-items-center justify-content-lg-end mb-0">
                    <label className="mx-2">Buscar:</label>
                    <input
                      type="text"
                      value={inputSearch}
                      onChange={(e) =>
                        dispatch({
                          type: ACTION_TYPES_ARTICULO.SEARCH_ARTICULO,
                          payload: e.target.value.toLowerCase(),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <Articulos popularProduct={false} />
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center justify-content-lg-end">
                  {[...Array(articulosPage.length)].map((_, idx) => (
                    <li
                      key={idx}
                      className={`page-item mx-1 ${
                        idx === page ? "active" : null
                      } `}
                      onClick={() =>
                        dispatch({
                          type: ACTION_TYPES_ARTICULO.SET_PAGE,
                          payload: idx,
                        })
                      }
                    >
                      <button className="page-link">{idx + 1}</button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Tienda;
