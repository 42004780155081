import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useArticulos } from "../hooks/useArticulos";
import { useCarrito } from "../hooks/useCarrito";
import { checkNumber } from "../utils/carritoReducer/carritoReducer";

function DetallesArticuloDescp() {
  const { articulo, colores } = useArticulos();

  return (
    <div className="tab-content mb-5" id="myTabContent">
      <div
        className="tab-pane fade show active"
        id="description"
        role="tabpanel"
        aria-labelledby="description-tab"
      >
        <div className="p-4 p-lg-5 bg-white">
          <h6 className="text-uppercase">Descripcion del Producto</h6>
          <p className="text-muted text-sm mb-0">
            {articulo[0]?.detalles?.descripcion?.descripcionLarga}
          </p>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="reviews"
        role="tabpanel"
        aria-labelledby="reviews-tab"
      ></div>
    </div>
  );
}

export default DetallesArticuloDescp;
