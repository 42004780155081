import React, { useState, useReducer, useEffect } from "react";
import Hero from "../../components/Hero";
import { fetchToken } from "../../services/services";
import { maintenanceReducer } from "../../utils/maintenanceReducer/maintenanceReducer";
import { ACTION_TYPES_MAINTENANCE } from "../../utils/maintenanceReducer/initialMaintenance";
import { useMaintenance } from "../../hooks/useMaintenance";

const InicioSesion = () => {
  const { dispatch, state } = useMaintenance();

  const handleInput = (e, param) => {
    const { value } = e.target;

    dispatch({ type: param, payload: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const responseToken = fetchToken({
      username: state.user,
      password: state.pass,
    });
    const dataToken = await responseToken;
    if (dataToken.success) {
      dispatch({
        type: ACTION_TYPES_MAINTENANCE.ADD_TOKEN,
        payload: dataToken.token,
      });
      dispatch({
        type: ACTION_TYPES_MAINTENANCE.INICIO_SECION,
        payload: true,
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Hero base={"Inicio"} go={"Inicio de Sesion"} />
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-6">
          <label htmlFor="inputEmail4" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            onChange={(e) => handleInput(e, ACTION_TYPES_MAINTENANCE.LOGINUSER)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="inputPassword4" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            onChange={(e) =>
              handleInput(e, ACTION_TYPES_MAINTENANCE.LOGINPASSWORD)
            }
            required
          />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Sign in
          </button>
        </div>
      </form>
    </>
  );
};

export default InicioSesion;
