export const Inputs = [
  {
    id: "primerNombre",
    label: "Primer Nombre",
    class: "col-lg-6",
    type: "text",
    placeholder: "Escriba su primer nombre",
  },
  {
    id: "primerApellido",
    label: "Primer Apellido",
    class: "col-lg-6",
    type: "text",
    placeholder: "Escriba su primer apellido",
  },
  {
    id: "correo",
    label: "Correo",
    class: "col-lg-6",
    type: "email",
    placeholder: "Escriba su Correo",
  },
  {
    id: "telefono",
    label: "Telefono",
    class: "col-lg-6",
    type: "number",
    placeholder: "Digite su telefono",
  },
  {
    id: "direccion2",
    label: "Direccion",
    class: "col-lg-12",
    type: "text",
    placeholder: "Escriba su Direccion",
  },
];
