import React, { useState } from "react";
import hands from "../../assets/img/hands-contact.jpg";
import Hero from "../../components/Hero";
import logo from "../../assets/img/LOGO.png";

function Contacto() {
  const [data, setData] = useState({});

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({
      ...data,
      [id]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    whatsAppMessage(data);
  };

  const whatsAppMessage = (item) => {
    window.location
      .replace(
        `https://wa.me/50687294117?text=Nombre:%20${data.Nombre}%0aApellido:${data.Apellido}%0aCorreo:${data.Correo}%0aTelefono:${data.Telefono}%0aComentario:${data.Comentario}`,
        "_blank"
      )
      .focus();
  };

  return (
    <>
      <Hero base={"Inicio"} go={"Contacto"} />
      <div className="container py-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 px-0 image-container">
            <img src={hands} alt="" className="img-fluid image-transparent" />
            <div className="content-over-image">
              <p>
                <span className="large">¡Transforma</span>{" "}
                <span className="small">
                  tu hogar en un oasis de confort y estilo con nuestros muebles
                  <span className="large"> de alta calidad!</span>
                </span>{" "}
                <span className="large"> Contáctanos </span>
                <span className="small">
                  y déjanos ayudarte a crear el espacio de tus
                </span>
                <span className="large"> sueños.</span>
              </p>
              <div className="icons"></div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 px-0 contacto-container d-flex flex-column justify-content-around align-items-center">
            <div className="logo-container align-self-end">
              <img src={logo} alt="" className="logo-image" />
            </div>
            <form className="container-fluid" onSubmit={handleSubmit}>
              <h3>Contáctenos</h3>
              <div className="row mt-4 mb-4">
                <div className="col-sm-6 col-md-6 col-lg-6 w-100">
                  <div className="row">
                    <div className="col">
                      <input
                        className="form-control form-control-lg input-transparent"
                        placeholder="Nombre"
                        id="Nombre"
                        onChange={handleInput}
                        required
                        type="text"
                      />
                    </div>
                    <div className="col">
                      <input
                        className="form-control form-control-lg input-transparent"
                        placeholder="Apellido"
                        id="Apellido"
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb-4">
                <div className="col-sm-6 col-md-6 col-lg-6 w-100">
                  <div className="row">
                    <div className="col">
                      <input
                        className="form-control form-control-lg input-transparent"
                        placeholder="Correo"
                        id="Correo"
                        onChange={handleInput}
                        type="email"
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        className="form-control form-control-lg input-transparent"
                        placeholder="Telefono"
                        id="Telefono"
                        onChange={handleInput}
                        required
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb-4">
                <div className="col-sm-6 col-md-6 col-lg-6 w-100">
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        className="form-control form-control-lg input-transparent"
                        placeholder="Deja un comentario"
                        id="Comentario"
                        onChange={handleInput}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb-4">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-sm-7 col-md-7">
                      <button
                        className="btn btn-light w-100 send-info"
                        type="submit"
                      >
                        Enviar
                        <span className="whatsapp-icon"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="copyright-container">
              <p>
                Copyright &copy; 2023 <span>All rights reserved</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <form id="formularioDatos">
        <input type="text" id="nombre" placeholder="Nombre" />
        <input type="text" id="descripcion" placeholder="Descripción" />
        <input type="file" id="archivoImagen" />
        <button type="button" id="botonEnviar">Enviar</button>
    </form> */}
    </>
  );
}

export default Contacto;
