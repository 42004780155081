import React from "react";
import categoriaBed from "../../assets/img/bed-categoria.jpg";
import categorieSala from "../../assets/img/sala-categoria.jpg";
import categorieCocina from "../../assets/img/cocina-categoria.jpeg";
import categorieBano from "../../assets/img/bano-categoria.jpg";
import { useNavigate } from "react-router-dom";
import Articulos from "../../components/Articulos";
import promo1 from "../../assets/sliders/promo1.jpeg";
import promo2 from "../../assets/sliders/promo2.jpeg";
import promo3 from "../../assets/sliders/promo3.jpeg";
import promo4 from "../../assets/sliders/promo4.jpeg";
import mb1 from "../../assets/sliders/MB-1.jpeg";
import mb2 from "../../assets/sliders/MB-2.jpeg";
import mb3 from "../../assets/sliders/MB-3.jpeg";
import mb4 from "../../assets/sliders/MB-4.jpeg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Link } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const setQuickAccess = (param) => {
    localStorage.setItem("HomeAccess", param);
    navigate("/tienda");
  };

  return (
    <div>
      <div className="container">
        {window.matchMedia("(min-width: 768px)").matches ? (
          <Splide
            options={{
              rewind: true,
              autoplay: true,
              interval: 4000,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide>
              <section
                className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ background: `url(${promo1})` }}
              ></section>
            </SplideSlide>
            <SplideSlide>
              <section
                className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ background: `url(${promo2})` }}
              ></section>
            </SplideSlide>
            <SplideSlide>
              <section
                className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ background: `url(${promo3})` }}
              ></section>
            </SplideSlide>
            <SplideSlide>
              <section
                className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ background: `url(${promo4})` }}
              ></section>
            </SplideSlide>
          </Splide>
        ) : (
          <Splide
            options={{
              rewind: true,
              autoplay: true,
              interval: 4000,
            }}
            aria-label="My Favorite Images"
          >
            <SplideSlide>
              <section
                className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ background: `url(${mb1})` }}
              ></section>
            </SplideSlide>
            <SplideSlide>
              <section
                className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ background: `url(${mb2})` }}
              ></section>
            </SplideSlide>
            <SplideSlide>
              <section
                className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ background: `url(${mb3})` }}
              ></section>
            </SplideSlide>
            <SplideSlide>
              <section
                className="hero pb-3 bg-cover bg-center d-flex align-items-center"
                style={{ background: `url(${mb4})` }}
              ></section>
            </SplideSlide>
          </Splide>
        )}

        <section className="pt-5">
          <header className="text-center">
            <p className="small text-muted small text-uppercase mb-1">
              Busca en nuestras
            </p>
            <h2 className="h5 text-uppercase mb-4">categorias</h2>
          </header>
          <div className="row">
            <div className="col-md-4 mb-md-0 mb-4">
              <a
                className="category-item h-100"
                onClick={() => setQuickAccess("Dormitorio")}
              >
                <img className="img-fluid h-100" src={categoriaBed} alt="" />
                <strong className="category-item-title">Dormitorio</strong>
              </a>
            </div>

            <div className="col-md-4 mb-md-0 mb-4">
              <a
                className="category-item mb-4"
                onClick={() => setQuickAccess("Sala")}
              >
                <img className="img-fluid" src={categorieSala} alt="" />
                <strong className="category-item-title">Sala</strong>
              </a>
              <a
                className="category-item"
                onClick={() => setQuickAccess("Baño")}
              >
                <img className="img-fluid" src={categorieBano} alt="" />
                <strong className="category-item-title">Baño</strong>
              </a>
            </div>

            <div className="col-md-4 ">
              <a
                className="category-item h-100"
                onClick={() => setQuickAccess("Cocina")}
              >
                <img className="img-fluid h-100" src={categorieCocina} alt="" />
                <strong className="category-item-title">Cocina</strong>
              </a>
            </div>
          </div>
        </section>

        <section className="pt-5">
          <header className="text-center">
            <p className="small text-muted small text-uppercase mb-1">
              Mira nuestros
            </p>
            <h2 className="h5 text-uppercase mb-4">proyectos</h2>
          </header>
          <div className="col-md-12">
            <Link href="*" className="category-item video-data"
            to="/portafolio"
          >
              <video loop autoPlay muted className="img-fluid">
                <source
                  src={require("../../assets/video/Fm Hidalgo..mp4")}
                  type="video/mp4"
                ></source>
              </video>
              <strong className="category-item-title">Portafolio</strong>
            </Link>
          </div>
        </section>

        <section className="py-5">
          <header>
            <p className="text-center small text-muted small text-uppercase mb-1">
              Nosotros hacemos realidad tus sueños
            </p>
            <h2 className="text-center h5 text-uppercase mb-4">
              Muebles populares
            </h2>
            {/* <QuickArticules /> */}
            <Articulos popularProduct={true} />
          </header>
        </section>
      </div>
    </div>
  );
}

export default Home;
