import React from "react";
import Hero from "../../components/Hero";
import Articulo from "../../components/CRUDS/Articulo";

const Mantenimiento = () => {
  return (
    <>
      <Hero base={"Inicio"} go={"Mantenimiento"} />
      <div className="container py-5">
        <Articulo/>
      </div>
    </>
  );
};

export default Mantenimiento;
