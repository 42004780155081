export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://localhost:44314/"
    : "https://fm-hidalgo.azurewebsites.net/";

//export const BASE_URL = "https://fm-hidalgo.azurewebsites.net/"; //asdasdasd

export const ENDPOINTS = {
  Articulos: "Articulo",
  Categorias: "Categorias",
  SubCategorias: "SubCategorias",
  ImgCategorias: "ImgCategorias",
  ImgArticulo: "ImgArticulo",
  Color: "Color",
  Token: "Token",
  TipColor: "TipColor",
  ArticuloPreviewImg: "ArticuloPreviewImg",
};

export const fetchToken = (data) =>
  fetch(`${BASE_URL}api/${ENDPOINTS.Token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchArticulos = () =>
  fetch(`${BASE_URL}api/${ENDPOINTS.Articulos}`)
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchArticulosById = (id) =>
  fetch(`${BASE_URL}api/${ENDPOINTS.Articulos}/${id}`)
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchCategorias = () =>
  fetch(`${BASE_URL}api/${ENDPOINTS.Categorias}`)
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchSubCategorias = () =>
  fetch(`${BASE_URL}api/${ENDPOINTS.SubCategorias}`)
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchColoresById = (id) =>
  fetch(`${BASE_URL}api/${ENDPOINTS.Color}/${id}`)
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchImgByIdArticulos = (id) =>
  fetch(`${BASE_URL}api/${ENDPOINTS.ImgArticulo}/${id}`)
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchColores = () =>
  fetch(`${BASE_URL}api/${ENDPOINTS.Color}`)
    .then((res) => res.json())
    .catch((err) => console.error(err));

/* ------------------------------------------------------------ */

export const fetchArticuloPost = (data,state) => 
  fetch(`${BASE_URL}api/${ENDPOINTS.Articulos}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${state.token}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchArticuloPrevImagePost = (formData,state) =>
  fetch(`${BASE_URL}api/${ENDPOINTS.ArticuloPreviewImg}`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${state.token}`,
    },
    body: formData,
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
  

export const fetchArticuloGeneralImagesPost = (data,state) =>
  fetch(`${BASE_URL}api/${ENDPOINTS.ImgArticulo}`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${state.token}`,
    },
    body: data,
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const fetchArticuloTipColorPost = (data,state) =>
  fetch(`${BASE_URL}api/${ENDPOINTS.TipColor}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${state.token}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
