import React from "react";
import { useNavigate } from "react-router-dom";
import { useArticulos } from "../hooks/useArticulos";
import { BASE_URL } from "../services/services";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import ModalDialog from "./ModalDialog";
import { ACTION_TYPES_ARTICULO } from "../utils/articulosReducer/initialStateArticulos";
import { useParams } from "react-router-dom";

function Articulos({ popularProduct }) {
  const [show, setShow] = useState(false);
  const [currentElement, setCurrentElement] = useState();

  const { dispatch, isLoading, page, articulosPage } = useArticulos();
  const { id } = useParams();
  const navigate = useNavigate();

  const navigateWithCode = (id) => {
    dispatch({
      type: ACTION_TYPES_ARTICULO.RESET_LOARDER_ARTICULO
    });
    navigate(`/tienda/${id}`);
  };

  return (
    <div className="row">
      <ModalDialog
        show={show}
        onHide={() => setShow(!show)}
        art={currentElement}
      />

      {isLoading ? (
        <div className="loader-custom">
          <div></div>
          <label>Cargando</label>
          <span className="loader-xd"></span>
        </div>
      ) : articulosPage[page] === undefined ? (
        <div className="loader-custom no-exists">
          <div></div>
          <label>Sin resultados disponibles</label>
        </div>
      ) : (
        articulosPage[page].map((articulo, idx) => {
          return popularProduct ? (
            idx < 3 ? (
              <div className="col-lg-4 col-sm-6" key={articulo.id}>
                <div className="product text-center">
                  <div className="mb-3 position-relative">
                    <div className="badge text-white bg-"></div>
                    <a
                      className="d-block"
                      onClick={() => {
                        if (id !== `${articulo.id}`) {
                          navigateWithCode(articulo.id);
                        } else {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }
                      }}
                    >
                      <img
                        className="img-fluid w-100"
                        src={
                          BASE_URL +
                          "resources/" +
                          articulo.imgPreviewArticulos.img
                        }
                        alt={articulo.nomArticulo}
                      />
                    </a>
                    <div className="product-overlay">
                      <span className="mb-0 list-inline">
                        <span className="list-inline-item m-0 p-0">
                          <span
                            className="btn btn-sm btn-dark"
                            onClick={() => {
                              if (id !== `${articulo.id}`) {
                                navigateWithCode(articulo.id);
                              } else {
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                  behavior: "smooth",
                                });
                              }
                            }}
                          >
                            Ver detalles
                          </span>
                        </span>
                        <span className="list-inline-item mr-0">
                          <Button
                            className="btn btn-sm btn-outline-dark"
                            variant="light"
                            onClick={() => {
                              setCurrentElement(articulo);
                              setShow(!show);
                            }}
                          >
                            <i className="fas fa-expand"></i>
                          </Button>
                        </span>
                      </span>
                    </div>
                  </div>
                  <h6>
                    {" "}
                    <a className="reset-anchor">{articulo.nomArticulo}</a>
                  </h6>
                  <p className="small text-muted">
                    ₡{Number(articulo.precio.costo).toLocaleString()}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="col-lg-4 col-sm-6" key={articulo.id}>
              <div className="product text-center">
                <div className="mb-3 position-relative">
                  <div className="badge text-white bg-"></div>
                  <a
                    className="d-block"
                    onClick={() => navigateWithCode(articulo.id)}
                  >
                    <img
                      className="img-fluid w-100"
                      src={
                        BASE_URL +
                        "resources/" +
                        articulo.imgPreviewArticulos.img
                      }
                      alt={articulo.nomArticulo}
                    />
                  </a>
                  <div className="product-overlay">
                    <span className="mb-0 list-inline">
                      <span className="list-inline-item m-0 p-0">
                        <span
                          className="btn btn-sm btn-dark"
                          onClick={() => navigateWithCode(articulo.id)}
                        >
                          Ver detalles
                        </span>
                      </span>
                      <span className="list-inline-item mr-0">
                        <Button
                          className="btn btn-sm btn-outline-dark"
                          variant="light"
                          onClick={() => {
                            setCurrentElement(articulo);
                            setShow(!show);
                          }}
                        >
                          <i className="fas fa-expand"></i>
                        </Button>
                      </span>
                    </span>
                  </div>
                </div>
                <h6>
                  {" "}
                  <a className="reset-anchor">{articulo.nomArticulo}</a>
                </h6>
                <p className="small text-muted">
                  ₡{Number(articulo.precio.costo).toLocaleString()}
                </p>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default Articulos;
