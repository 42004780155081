import { ACTION_TYPES_MAINTENANCE } from "./initialMaintenance";

export const maintenanceReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES_MAINTENANCE.ADD_TOKEN:

      return {
        ...state,
        token: action.payload,
      };

    case ACTION_TYPES_MAINTENANCE.INICIO_SECION:
      return {
        ...state,
        isLogin: action.payload,
      };

    case ACTION_TYPES_MAINTENANCE.LOGINUSER:
      return {
        ...state,
        user: action.payload,
      };

    case ACTION_TYPES_MAINTENANCE.LOGINPASSWORD:
      return {
        ...state,
        pass: action.payload,
      };

    default:
      return state;
  }
};
