import React from "react";
import Gallery from "react-photo-gallery";
import Hero from "../../components/Hero";
import { photos } from "../../utils/photos";

const Portafolio = () => {
  //
  return (
    <>
      <Hero base={"Inicio"} go={"Portafolio"} />
      <div className="container py-4">
        <h4 className="py-4">Unas pocas fotos de nuestros trabajos pasados</h4>
        {/* <video loop autoPlay muted className="img-fluid">
                <source
                  src={require("../../assets/video/vid1_bien..mp4")}
                  type="video/mp4"
                ></source>
              </video> */}
        <Gallery photos={photos} />
        <h4 className="pt-4">
          Recuerda que tienes un proyecto te puedes poner en contacto con
          nosotros por Whatsapp!
        </h4>
      </div>
    </>
  );
};

export default Portafolio;
