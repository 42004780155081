import { createContext, useEffect, useReducer } from "react";
import { maintenanceReducer } from "../../utils/maintenanceReducer/maintenanceReducer";
import {
  INITIAL_STATE_MAINTENANCE,
  ACTION_TYPES_MAINTENANCE,
} from "../../utils/maintenanceReducer/initialMaintenance";

export const MaintenanceContect = createContext(INITIAL_STATE_MAINTENANCE);

export const MaintenanceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(maintenanceReducer,INITIAL_STATE_MAINTENANCE);

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify( state.token));
  },[state.token]);

  return (
    <MaintenanceContect.Provider value={{ dispatch, state}}>
      {children}
    </MaintenanceContect.Provider>
  );
};
