import { ACTION_TYPES_ARTICULO } from "./initialStateArticulos";

export const articulosReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES_ARTICULO.SETARTICULOGUARDADO:
      return {
        ...state,
        articuloGuardado: action.payload,
      };
    case ACTION_TYPES_ARTICULO.RESETADD:
      return {
        ...state,
        articuloGeneralImages: null,
        articuloPrevImage: null,
        articulosSuCategoriaSelecionado:[],
        articuloColoresSeleccionados:[],
        articuloDescripcionLarga:'',
        articuloDescripcionCorta:'',
        articuloPrecio:0,
        articuloCodigo:'',
        articuloNombre:'',
      };
    case ACTION_TYPES_ARTICULO.SET_ARTICULO_GENERAL_IMAGE:
      return {
        ...state,
        articuloGeneralImages: action.payload,
      };
    case ACTION_TYPES_ARTICULO.SET_ARTICULO_PREV_IMAGE:
      return {
        ...state,
        articuloPrevImage: action.payload,
      };
    case ACTION_TYPES_ARTICULO.SET_ARTICULO_SUBCATEGORIA_SELECCIONADO:
      return {
        ...state,
        articulosSuCategoriaSelecionado: action.payload,
      };
    case ACTION_TYPES_ARTICULO.SET_ARTICULO_SUBCATEGORIAS:
      return {
        ...state,
        articulosSuCategoria: action.payload,
      };
    case ACTION_TYPES_ARTICULO.SET_ARTICULO_COLORES_SELECCIONADOS:
      return {
        ...state,
        articuloColoresSeleccionados: action.payload,
      };
    case ACTION_TYPES_ARTICULO.SET_ARTICULO_COLORES:
      return {
        ...state,
        articuloColores: action.payload,
      };

    case ACTION_TYPES_ARTICULO.SET_ARTICULO_DESC_CORTA:
      return {
        ...state,
        articuloDescripcionCorta: action.payload,
      };

    case ACTION_TYPES_ARTICULO.SET_ARTICULO_DESC_LARGA:
      return {
        ...state,
        articuloDescripcionLarga: action.payload,
      };

    case ACTION_TYPES_ARTICULO.SET_ARTICULO_PRECIO:
      return {
        ...state,
        articuloPrecio: action.payload,
      };

    case ACTION_TYPES_ARTICULO.SET_ARTICULO_CODIGO:
      return {
        ...state,
        articuloCodigo: action.payload,
      };

    case ACTION_TYPES_ARTICULO.SET_ARTICULO_NOMBRE:
      return {
        ...state,
        articuloNombre: action.payload,
      };

    case ACTION_TYPES_ARTICULO.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        initialArticulo: action.payload.data,
      };

    case ACTION_TYPES_ARTICULO.FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case ACTION_TYPES_ARTICULO.FETCH_ARTICULO_BY_ID: {
      return {
        ...state,
        articulo: action.payload,
        isLoadingDetalles: false,
      };
    }

    case ACTION_TYPES_ARTICULO.FETCH_COLORS_BY_ID: {
      return {
        ...state,
        colores: action.payload,
      };
    }

    case ACTION_TYPES_ARTICULO.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case ACTION_TYPES_ARTICULO.FILTER_CAT_BY_CATEGORIA:
      return {
        ...state,
        page: 0,
        articuloByFilters: [...state.initialArticulo].filter(
          (item) =>
            item.subCategorias.categorias.nomCategoria ===
            action.payload.nomCategoria
        ),
        filterState: true,
        inputSearch: state.inputSearch !== "" ? (state.inputSearch = "") : "",
      };

    case ACTION_TYPES_ARTICULO.FILTER_CAT_BY_SUBCATEGORIA:
      return {
        ...state,
        page: 0,
        articuloByFilters: [...state.initialArticulo].filter(
          (item) =>
            item.subCategorias.nomSubCategoria ===
            action.payload.nomSubCategoria
        ),
        filterState: true,
        inputSearch: state.inputSearch !== "" ? (state.inputSearch = "") : "",
      };

    case ACTION_TYPES_ARTICULO.SEARCH_ARTICULO:
      if (action.payload) {
        return {
          ...state,
          page: 0,
          articuloByFilters: [...state.initialArticulo].filter(
            (item) =>
              item.nomArticulo.toLowerCase().includes(action.payload) ||
              item.codArticulo.toLowerCase().includes(action.payload)
          ),
          filterState: true,
          inputSearch: action.payload,
        };
      }
      return {
        ...state,
        articuloByFilters: [],
        filterState: false,
        inputSearch: "",
      };

    case ACTION_TYPES_ARTICULO.SET_INITIAL_DATA:
      return {
        ...state,
        page: 0,
        articuloByFilters: [...state.initialArticulo],
        inputSearch: "",
      };

    case ACTION_TYPES_ARTICULO.RESET:
      return {
        ...state,
        articuloByFilters: [],
        filterState: false,
        inputSearch: "",
      };

    case ACTION_TYPES_ARTICULO.RESET_LOARDER_ARTICULO:
      return {
        ...state,
        isLoadingDetalles: true,
      };

    case ACTION_TYPES_ARTICULO.NO_EXISTS:
      return {
        ...state,
        noExists: action.payload,
      };

    default:
      return state;
  }
};
