import img1 from "../assets/test/show1.jpeg";
import img2 from "../assets/test/show2.jpeg";
import img3 from "../assets/test/show3.jpeg";
import img4 from "../assets/test/show4.jpeg";
import img5 from "../assets/test/show5.jpeg";
import img6 from "../assets/test/show6.jpeg";
import img7 from "../assets/test/show7.jpeg";
import img8 from "../assets/test/show8.jpeg";
import img9 from "../assets/test/show9.jpeg";
import img10 from "../assets/test/show10.jpeg";
import img11 from "../assets/test/show11.jpeg";
import img12 from "../assets/test/show12.jpeg";
import img13 from "../assets/test/show13.jpeg";
import img14 from "../assets/test/show14.jpeg";
import img15 from "../assets/test/show15.jpeg";
import img16 from "../assets/test/show16.jpeg";
import img17 from "../assets/test/show17.jpeg";
import img18 from "../assets/test/show18.jpeg";
import img19 from "../assets/test/show19.jpeg";
import img20 from "../assets/test/show20.jpeg";
import img21 from "../assets/test/show21.jpeg";
import img22 from "../assets/test/show22.jpeg";
import img23 from "../assets/test/show23.jpeg";
import img24 from "../assets/test/show24.jpeg";
import img25 from "../assets/test/show25.jpeg";
import img26 from "../assets/test/show26.jpeg";
import img27 from "../assets/test/show27.jpeg";
import img28 from "../assets/test/show28.jpeg";
import img30 from "../assets/test/show30.jpeg";
import img31 from "../assets/test/show31.jpeg";
import img32 from "../assets/test/show32.jpeg";
import img33 from "../assets/test/show33.jpeg";
import img34 from "../assets/test/show34.jpg";
import img35 from "../assets/test/show35.jpg";
import img36 from "../assets/test/show36.jpg";
import img37 from "../assets/test/show37.jpg";
import img38 from "../assets/test/show38.jpg";
import img39 from "../assets/test/show39.jpg";
import img40 from "../assets/test/show40.jpg";
import img41 from "../assets/test/show41.jpg";
import img42 from "../assets/test/show42.jpg";
import img43 from "../assets/test/show43.jpg";
import img44 from "../assets/test/show44.jpg";
import img45 from "../assets/test/show45.jpg";
import img46 from "../assets/test/show46.jpg";
import img47 from "../assets/test/show47.jpg";
import img48 from "../assets/test/show48.jpg";
import img49 from "../assets/test/show49.jpg";
import img50 from "../assets/test/show50.jpg";
import img51 from "../assets/test/show51.jpg";
import img52 from "../assets/test/show52.jpg";
import img53 from "../assets/test/show53.jpg";
import img54 from "../assets/test/show54.jpg";
import img55 from "../assets/test/show55.jpg";
import img56 from "../assets/test/show56.jpg";
import img57 from "../assets/test/show57.jpg";
import img58 from "../assets/test/show58.jpg";
import img59 from "../assets/test/show59.jpg";
import img60 from "../assets/test/show60.jpg";
import img61 from "../assets/test/show61.jpg";
import img62 from "../assets/test/show62.jpg";
import img63 from "../assets/test/show63.jpg";
import img64 from "../assets/test/show64.jpg";
import img65 from "../assets/test/show65.jpg";
import img66 from "../assets/test/show66.jpg";
import img67 from "../assets/test/show67.jpg";
import img68 from "../assets/test/show68.jpg";
import img69 from "../assets/test/show69.jpg";
import img70 from "../assets/test/show70.jpg";
import img1n from "../assets/test/shown1.jpg";
import img2n from "../assets/test/shown2.jpg";
import img3n from "../assets/test/shown3.jpg";
import img4n from "../assets/test/shown4.jpg";
import img5n from "../assets/test/shown5.jpg";
import img6n from "../assets/test/shown6.jpg";
import img7n from "../assets/test/shown7.jpg";
import img8n from "../assets/test/shown8.jpg";
import img9n from "../assets/test/shown9.jpg";
import img10n from "../assets/test/shown10.jpg";
import img11n from "../assets/test/shown11.jpg";
import img12n from "../assets/test/shown12.jpg";
import img13n from "../assets/test/shown12.jpg";

export const photos = [
  {
    src: img1n,
    width: 3,
    height: 3,
  },
  {
    src: img2n,
    width: 4,
    height: 3,
  },
  {
    src: img3n,
    width: 4,
    height: 3,
  },
  {
    src: img4n,
    width: 4,
    height: 3,
  },
  {
    src: img5n,
    width: 4,
    height: 3,
  },
  {
    src: img6n,
    width: 4,
    height: 3,
  },
  {
    src: img7n,
    width: 4,
    height: 3,
  },
  {
    src: img8n,
    width: 4,
    height: 3,
  },
  {
    src: img9n,
    width: 4,
    height: 3,
  },
  {
    src: img10n,
    width: 4,
    height: 3,
  },
  {
    src: img11n,
    width: 1,
    height: 1,
  },
  {
    src: img12n,
    width: 4,
    height: 3,
  },
  {
    src: img13n,
    width: 4,
    height: 3,
  },
  {
    src: img70,
    width: 3,
    height: 3,
  },
  {
    src: img69,
    width: 4,
    height: 3,
  },
  {
    src: img68,
    width: 4,
    height: 3,
  },
  {
    src: img67,
    width: 4,
    height: 3,
  },
  {
    src: img66,
    width: 4,
    height: 3,
  },
  {
    src: img65,
    width: 4,
    height: 3,
  },
  {
    src: img64,
    width: 4,
    height: 3,
  },
  {
    src: img63,
    width: 4,
    height: 3,
  },
  {
    src: img62,
    width: 4,
    height: 3,
  },
  {
    src: img61,
    width: 4,
    height: 3,
  },
  {
    src: img60,
    width: 1,
    height: 1,
  },
  {
    src: img59,
    width: 4,
    height: 3,
  },
  {
    src: img58,
    width: 4,
    height: 3,
  },
  {
    src: img57,
    width: 4,
    height: 3,
  },
  {
    src: img56,
    width: 4,
    height: 3,
  },
  {
    src: img55,
    width: 4,
    height: 3,
  },
  {
    src: img54,
    width: 4,
    height: 3,
  },
  {
    src: img53,
    width: 4,
    height: 3,
  },
  {
    src: img52,
    width: 4,
    height: 3,
  },
  {
    src: img51,
    width: 4,
    height: 3,
  },
  {
    src: img50,
    width: 4,
    height: 3,
  },
  {
    src: img49,
    width: 4,
    height: 3,
  },
  {
    src: img48,
    width: 4,
    height: 3,
  },
  {
    src: img47,
    width: 4,
    height: 3,
  },
  {
    src: img46,
    width: 4,
    height: 3,
  },
  {
    src: img45,
    width: 4,
    height: 3,
  },
  {
    src: img44,
    width: 4,
    height: 3,
  },
  {
    src: img43,
    width: 4,
    height: 3,
  },
  {
    src: img42,
    width: 4,
    height: 3,
  },
  {
    src: img41,
    width: 4,
    height: 3,
  },
  {
    src: img40,
    width: 4,
    height: 3,
  },
  {
    src: img39,
    width: 4,
    height: 3,
  },
  {
    src: img38,
    width: 4,
    height: 3,
  },
  {
    src: img37,
    width: 4,
    height: 3,
  },
  {
    src: img36,
    width: 4,
    height: 3,
  },
  {
    src: img35,
    width: 4,
    height: 3,
  },
  {
    src: img34,
    width: 4,
    height: 3,
  },
  {
    src: img1,
    width: 4,
    height: 3,
  },
  {
    src: img2,
    width: 4,
    height: 3,
  },
  {
    src: img3,
    width: 4,
    height: 3,
  },
  {
    src: img4,
    width: 4,
    height: 3,
  },
  {
    src: img5,
    width: 3,
    height: 3,
  },
  {
    src: img6,
    width: 4,
    height: 3,
  },
  {
    src: img7,
    width: 5,
    height: 3,
  },
  {
    src: img8,
    width: 4,
    height: 3,
  },
  {
    src: img9,
    width: 4,
    height: 3,
  },
  {
    src: img10,
    width: 3,
    height: 3,
  },
  {
    src: img11,
    width: 3,
    height: 3,
  },
  {
    src: img12,
    width: 3,
    height: 3,
  },
  {
    src: img13,
    width: 4,
    height: 3,
  },
  {
    src: img14,
    width: 4,
    height: 3,
  },
  {
    src: img15,
    width: 4,
    height: 3,
  },
  {
    src: img16,
    width: 3,
    height: 3,
  },
  {
    src: img17,
    width: 3,
    height: 3,
  },
  {
    src: img18,
    width: 4,
    height: 3,
  },
  {
    src: img19,
    width: 3,
    height: 3,
  },
  {
    src: img20,
    width: 3,
    height: 3,
  },
  {
    src: img21,
    width: 4,
    height: 3,
  },
  {
    src: img22,
    width: 4,
    height: 3,
  },
  {
    src: img23,
    width: 4,
    height: 3,
  },
  {
    src: img24,
    width: 4,
    height: 3,
  },
  {
    src: img25,
    width: 4,
    height: 3,
  },
  {
    src: img26,
    width: 4,
    height: 3,
  },
  {
    src: img27,
    width: 4,
    height: 3,
  },
  {
    src: img28,
    width: 4,
    height: 3,
  },
  {
    src: img30,
    width: 4,
    height: 3,
  },
  {
    src: img31,
    width: 4,
    height: 3,
  },
  {
    src: img32,
    width: 4,
    height: 3,
  },
  {
    src: img33,
    width: 4,
    height: 3,
  },
  {
    src: img30,
    width: 4,
    height: 3,
  },
  {
    src: img31,
    width: 4,
    height: 3,
  },
  {
    src: img32,
    width: 4,
    height: 3,
  },
];
