import React from "react";
import { useCarrito } from "../hooks/useCarrito";
import { iva, subTotal } from "../utils/total";

function Total() {
  const { state } = useCarrito();

  return (
    <div className="card border-0 rounded-0 p-lg-4 bg-light">
      <div className="card-body">
        <h5 className="text-uppercase mb-4">Carrito total</h5>
        <ul className="list-unstyled mb-0">
          <li className="d-flex align-items-center justify-content-between">
            <strong className="text-uppercase small font-weight-bold">
              Subtotal
            </strong>
            <span className="text-muted small">
              ₡ {subTotal(state?.carrito).toLocaleString()}
            </span>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <strong className="text-uppercase small font-weight-bold">
              Iva
            </strong>
            <span className="text-muted small">
              ₡ {iva(state.carrito).toLocaleString()}
            </span>
          </li>
          <li className="border-bottom my-2"></li>
          <li className="d-flex align-items-center justify-content-between mb-4">
            <strong className="text-uppercase small font-weight-bold">
              Total
            </strong>
            <span>
              ₡{(subTotal(state.carrito) + iva(state.carrito)).toLocaleString()}
            </span>
          </li>
          <li></li>
        </ul>
      </div>
    </div>
  );
}

export default Total;
