import React from "react";
import Hero from "../../components/Hero";
import cohete from "../../assets/about/cohete.png";
import luz from "../../assets/about/luz.png";
import portapapeles from "../../assets/about/portapapeles.png";

const Acerca = () => {
  //
  return (
    <div>
      <Hero base={"Inicio"} go={"Acerca de nosotros"} />
      <div className="container py-5">
        <div className="row about-us-content">
          <div className="col-sm-12 col-lg-6 col-xl-6 about-us-content-first">
            <label className="about-us-information">
              <span>FM Hidalgo</span> es una empresa encargada de diseñar
              fabricar e instalar proyectos de ebanistería. Somos una empresa en
              constante crecimiento en busca de brindar confianza y alianzas con
              nuestros clientes. Nuestra empresa se localiza en San Sebastián{" "}
              <span>(San José)</span>, nos encontramos al día con asuntos de
              Hacienda y sin ningún tipo de problema con la facturación
              electrónica. Todos nuestros ebanistas cuentan con un mínimo de 10
              años de experiencia con capacidad de trabajar en macro. Para
              nosotros seria un gusto que nos puedan tomar en cuenta en algún
              proyecto de su empresa y poder formar parte de su equipo de
              trabajo.
            </label>
          </div>
          <div className="col-lg-6 col-xl-6 col-sm-6 d-none d-lg-block d-xl-block">
            <div className="image-about-us"></div>
          </div>
        </div>
        <div className="divisor-yellow-left"></div>
        <div className="row">
          <h2 className="objetivos">Objetivos a cumplir</h2>
          <div className="col-lg-4">
            <img
              className="rounded-circle"
              src={cohete}
              alt="Generic placeholder image"
              width="140"
              height="140"
            />
            <h5>Satisfacción del cliente con nuestro trabajo</h5>
          </div>
          <div className="col-lg-4">
            <img
              className="rounded-circle"
              src={luz}
              alt="Generic placeholder image"
              width="140"
              height="140"
            />
            <h5>Planificación y diseño</h5>
          </div>
          <div className="col-lg-4">
            <img
              className="rounded-circle"
              src={portapapeles}
              alt="Generic placeholder image"
              width="140"
              height="140"
            />
            <h5>
              Cotización (de acuerdo al presupuestos de nuestros clientes)
            </h5>
          </div>
        </div>
        <div className="divisor-yellow-right"></div>
        <div className="row about-us-content">
          <div className="col-lg-6 col-xl-6 col-sm-6 d-none d-lg-block d-xl-block">
            <div className="image-about-us-2"></div>
          </div>
          <div className="col-sm-12 col-lg-6 col-xl-6 about-us-content-first">
            <div className="about-us-information-textos">
              <div className="row">
                <div className="col-lg-4 col-xl-4 col-sm-4 col-4 left-text">
                  <h2>Misión</h2>
                </div>
                <div className="col-lg-8 col-xl-8 col-sm-8 col-8 second-left-text">
                  <label className="about-us-information">
                    Compromiso total con el proyecto de nuestros clientes, alta
                    calidad de nuestros productos a un bajo costo y personal y
                    mano de obra altamente calificado.
                  </label>
                </div>
              </div>
            </div>
            <div className="about-us-information-textos">
              <div className="row">
                <div className="col-lg-4 col-xl-4 col-sm-4 col-4 left-text">
                  <h2>Visión</h2>
                </div>
                <div className="col-lg-8 col-xl-8 col-sm-8 col-8 second-left-text">
                  <label className="about-us-information">
                    Convertirnos en una empresa, reconocida en la región del
                    país, por medio de un exepcional servicio a nuestros
                    clientes.
                  </label>
                </div>
              </div>
            </div>
            <div className="about-us-information-textos">
              <div className="row">
                <div className="col-lg-4 col-xl-4 col-sm-4 col-4 left-text">
                  <h2>Valores</h2>
                </div>
                <div className="col-lg-8 col-xl-8 col-sm-8 col-8 second-left-text">
                  <label className="about-us-information">
                    Nuestro Compromiso, Puntualidad, Orden y por ultimo la
                    Confianza.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acerca;
