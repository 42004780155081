import React from "react";
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from "../services/services";

function ModalDialog(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="custom-header"></Modal.Header>
      <Modal.Body>
        <div className="row align-items-stretch">
          <div className="col-lg-6 p-lg-0">
            <a
              className="glightbox product-view d-block h-100 bg-cover bg-center"
              // style="background: url(img/product-5.jpg)"
              // src=
              data-gallery="gallery1"
              data-glightbox="Red digital smartwatch"
            >
              <div
                // src={
                //   BASE_URL + "resources/" + props.art?.imgPreviewArticulos.img
                // }
                // alt=""
                style={{
                  backgroundImage: `url(${
                    BASE_URL + "resources/" + props.art?.imgPreviewArticulos.img
                  })`,
                }}
                className="custom-image"
              ></div>
            </a>
            <a
              className="glightbox d-none"
              // href="img/product-5-alt-1.jpg"
              // data-gallery="gallery1"
              data-glightbox="Red digital smartwatch"
            ></a>
            <a
              className="glightbox d-none"
              // href="img/product-5-alt-2.jpg"
              // data-gallery="gallery1"
              data-glightbox="Red digital smartwatch"
            ></a>
          </div>
          <div className="col-lg-6">
            <div className="p-4 my-md-4">
              <h2 className="h4">{props.art?.nomArticulo}</h2>
              <p className="text-muted">
                ₡{props.art?.precio.costo.toLocaleString()}
              </p>
              <p className="text-sm mb-4">
                {props.art?.detalles.descripcionCorta}
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalDialog;
