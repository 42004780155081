import React, { useEffect } from "react";
import { useArticulos } from "../../hooks/useArticulos";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ACTION_TYPES_ARTICULO } from "../../utils/articulosReducer/initialStateArticulos";
import {
  fetchArticuloPost,
  fetchArticuloPrevImagePost,
  fetchArticuloGeneralImagesPost,
  fetchArticuloTipColorPost,
} from "../../services/services";
import { useMaintenance } from "../../hooks/useMaintenance";

const animatedComponents = makeAnimated();

const Articulo = () => {
  const {
    dispatch,
    state,
    handleFetchArticulosColores,
    handleFetchArticulosSubCategorias,
  } = useArticulos();

  const stateMaintenance = useMaintenance();

  useEffect(() => {
    handleFetchArticulosColores();
    handleFetchArticulosSubCategorias();
  }, []);

  const handleChangeValue = (e, param, select = false) => {
    if (select !== true) {
      const { value } = e.target;
      dispatch({ type: param, payload: value });
    } else {
      dispatch({ type: param, payload: e });
    }
  };

  const handleChangeValueImage = (e, param) => {
    dispatch({ type: param, payload: e.target.files });
  };

  const handleCreateArticuloPrevImage = () => {
    let formData = new FormData();

    const data = state.articuloPrevImage;

    for (let index = 0; index < data.length; index++) {
      formData.append("Fotos", data[index]);
    }

    fetchArticuloPrevImagePost(formData, stateMaintenance.state).then(
      (info) => {
        handleCreateArticuloJson(info.idPreview);
      }
    );
  };

  const handleCreateArticuloJson = (id) => {
    let jsonData = {
      idSubCategoria: state.articulosSuCategoriaSelecionado.id,
      idImgPreviewArticulo: id,
      nomArticulo: state.articuloNombre,
      codArticulo: state.articuloCodigo,
      precio: {
        iva: 0.0,
        costo: state.articuloPrecio,
      },
      detalles: {
        idDesAdicional: 1,
        descripcion: {
          descripcionLarga: state.articuloDescripcionLarga,
          ancho: " ",
          alto: " ",
          fondo: " ",
        },
        descripcionCorta: state.articuloDescripcionCorta,
      },
    };

    fetchArticuloPost(jsonData, stateMaintenance.state).then((info) => {
      handleCreateArticuloImages(info.idArticulo);
    });
  };

  const handleCreateArticuloImages = (id) => {
    let formData = new FormData();
    formData.append("IdArticulo", id);
    for (let index = 0; index < state.articuloGeneralImages.length; index++) {
      formData.append("Fotos", state.articuloGeneralImages[index]);
    }

    fetchArticuloGeneralImagesPost(formData, stateMaintenance.state).then(
      () => {
        handleCreateArticuloTipoColor(id);
      }
    );
  };

  const handleCreateArticuloTipoColor = (id) => {
    const array = [];
    for (
      let index = 0;
      index < state.articuloColoresSeleccionados.length;
      index++
    ) {
      array.push({
        IdColor: state.articuloColoresSeleccionados[index].id,
        IdArticulo: id,
      });
    }

    fetchArticuloTipColorPost(array, stateMaintenance.state).then(() => {
      dispatch({
        type: ACTION_TYPES_ARTICULO.RESETADD,
      });
      dispatch({
        type: ACTION_TYPES_ARTICULO.SETARTICULOGUARDADO,
        payload: true,
      });
    });
  };

  if (!stateMaintenance.state.isLogin) {
    return <>No estas logueado</>;
  }

  if (state.articuloGuardado) {
    return (
      <div class="container py-5">
        <span>Articulo guardado</span>
        <button
          onClick={() => {
            dispatch({
              type: ACTION_TYPES_ARTICULO.SETARTICULOGUARDADO,
              payload: false,
            });
          }}
        >
          agregar nuevo?
        </button>
      </div>
    );
  }

  return (
    <div>
      <input
        className="form-control form-control-lg input-transparent"
        type="text"
        placeholder="Codigo"
        onChange={(e) =>
          handleChangeValue(e, ACTION_TYPES_ARTICULO.SET_ARTICULO_CODIGO)
        }
      />
      <input
        className="form-control form-control-lg input-transparent"
        type="text"
        placeholder="Nombre"
        onChange={(e) =>
          handleChangeValue(e, ACTION_TYPES_ARTICULO.SET_ARTICULO_NOMBRE)
        }
      />
      <input
        className="form-control form-control-lg input-transparent"
        type="number"
        placeholder="Precio"
        onChange={(e) =>
          handleChangeValue(e, ACTION_TYPES_ARTICULO.SET_ARTICULO_PRECIO)
        }
      />
      <textarea
        className="form-control form-control-lg input-transparent"
        type="text"
        placeholder="Descripcion Corta"
        onChange={(e) =>
          handleChangeValue(e, ACTION_TYPES_ARTICULO.SET_ARTICULO_DESC_CORTA)
        }
      />
      <textarea
        className="form-control form-control-lg input-transparent"
        type="text"
        placeholder="Descripcion Larga"
        onChange={(e) =>
          handleChangeValue(e, ACTION_TYPES_ARTICULO.SET_ARTICULO_DESC_LARGA)
        }
      />
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={state.articuloColores}
        getOptionLabel={(option) => option.nomColor + " ₡" + option.precioColor}
        getOptionValue={(option) => option.id}
        onChange={(a) => {
          handleChangeValue(
            a,
            ACTION_TYPES_ARTICULO.SET_ARTICULO_COLORES_SELECCIONADOS,
            true
          );
        }}
      />
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        options={state.articulosSuCategoria}
        getOptionLabel={(option) =>
          option.nomSubCategoria + " => " + option.categorias.nomCategoria
        }
        getOptionValue={(option) => option.id}
        onChange={(a) => {
          handleChangeValue(
            a,
            ACTION_TYPES_ARTICULO.SET_ARTICULO_SUBCATEGORIA_SELECCIONADO,
            true
          );
        }}
      />
      <div>
        <label>Imagen Preview</label>
        <input
          type="file"
          id="archivoImagen"
          accept="image/png, image/jpeg"
          onChange={(e) => {
            handleChangeValueImage(
              e,
              ACTION_TYPES_ARTICULO.SET_ARTICULO_PREV_IMAGE
            );
          }}
        />
      </div>
      <div>
        <label>Imagenes generales</label>
        <input
          type="file"
          id="archivoImagen"
          accept="image/png, image/jpeg"
          multiple
          onChange={(e) => {
            handleChangeValueImage(
              e,
              ACTION_TYPES_ARTICULO.SET_ARTICULO_GENERAL_IMAGE
            );
          }}
        />
      </div>
      <button onClick={handleCreateArticuloPrevImage}>guardar</button>
    </div>
  );
};

export default Articulo;
