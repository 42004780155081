export const INITIAL_STATE_MAINTENANCE = {
    token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : {},
    isLogin: false,
    user:'',
    pass:'',
  };
  
  export const ACTION_TYPES_MAINTENANCE = {
    ADD_TOKEN: "ADD_TOKEN",
    SHOW_TOKEN: "SHOW_TOKEN",
    INICIO_SECION: "INICIO_SECION",
    CHANGE_PAGE: "CHANGE_PAGE",
    SHOW_LOGIN:"SHOW_LOGIN",
    LOGINUSER: "LOGINUSER",
    LOGINPASSWORD: "LOGINPASSWORD",
  };
  