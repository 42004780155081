import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation, Pagination } from "swiper";
import { BASE_URL } from "../services/services";

function ModalSwiper(props) {
  return (
    <>
      <Modal
        {...props}
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className="custom-header"></Modal.Header>
        <Modal.Body
          style={{
            padding: "0",
          }}
        >
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              userSelect: "none",
            }}
            zoom={true}
            navigation={true}
            modules={[Zoom, Navigation]}
            className="mySwiper"
            loop={true}
          >
            {props.copyarr.map((item, idx) => (
              <SwiperSlide key={idx}>
                <div className="swiper-zoom-container">
                  <img src={BASE_URL + "resources/" + item.img} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalSwiper;
